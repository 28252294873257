import React, { useState, useEffect } from "react";
import { heroBanner } from "../assets";
import { buttonClick } from "../animations";
import { MdCardTravel, IoCameraSharp, CiLocationOn } from "../assets/icons";
import { motion } from "framer-motion";
import "../assets/css/main.css";

const HeroSection = () => {
  const [travelers, setTravelers] = useState(0);
  const [treasures, setTreasures] = useState(0);
  const [locations, setLocations] = useState(0);
  const incrementNumbers = (target, setter) => {
    let count = 0;
    const interval = setInterval(() => {
      if (count < target) {
        count += Math.ceil(target / 100);
        if (count > target) count = target;
        setter(count);
      } else {
        clearInterval(interval);
      }
    }, 20);
  };

  useEffect(() => {
    incrementNumbers(20409, setTravelers);
    incrementNumbers(150, setTreasures);
    incrementNumbers(200, setLocations);
  }, []);
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="flex flex-col items-start justify-start gap-6">
        <p className="text-[40px] text-headingColor md:text-[30px] font-sans font-bold">
          Dimentica il lavoro, rilassati per una vacanza.
        </p>
        <p className="text-gray-400 text-lg">
          Vivi le bellezze della nostra città e goditi il B&B più bello.
        </p>
        <motion.button
          {...buttonClick}
          className="mb-7 bg-headingColor px-6 py-4 rounded-md text-primary text-base font-semibold shadow-lg"
        >
          Prenota una stanza.
        </motion.button>
        <div className="w-full flex justify-around">
          <div className="col-auto d-flex flex-wrap flex-col items-center content-center bg-white p-4 rounded-md shadow-lg">
            <MdCardTravel className="text-4xl text-headingColor" />
            <span className="text-secondary text-sm font-semibold">
              {travelers}
            </span>{" "}
            <br />
            Viaggiatori
          </div>
          <div className="col-auto d-flex flex-wrap flex-col items-center content-center bg-white p-4 rounded-md shadow-lg">
            <IoCameraSharp className="text-4xl text-headingColor" />
            <span className="text-secondary text-sm font-semibold">
              {treasures}+
            </span>{" "}
            <br />
            Tesori
          </div>
          <div className="col-auto d-flex flex-wrap flex-col items-center content-center bg-white p-4 rounded-md shadow-lg">
            <CiLocationOn className="text-4xl text-headingColor" />
            <span className="text-secondary text-sm font-semibold">
              {locations}+
            </span>{" "}
            <br />
            Locali da visitare
          </div>
        </div>
      </div>
      <div className="py-2 flex-1 flex items-center justify-end relative">
        <img
          src={heroBanner}
          alt="heroBanner"
          style={{
            borderTopLeftRadius: "110px",
            borderTopRightRadius: "30px",
            borderBottomRightRadius: "30px",
            borderBottomLeftRadius: "30px",
            boxShadow: "4px 3px 4px 0px gray",
          }}
        />
      </div>
    </div>
  );
};

export default HeroSection;
