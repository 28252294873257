import React from 'react';
import { motion } from "framer-motion";


const Gallery = () => {
  return (
    <div>
        Gallery
    </div>
  )
}

export default Gallery