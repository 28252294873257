// Action per settare i contatti nel Redux store
export const setAllContacts = (contacts) => {
  return {
    type: "SET_ALL_CONTACTS",
    contacts,
  };
};

export const removeContact = (id) => {
  return {
    type: "REMOVE_CONTACT",
    payload: id, // Passa l'id del contatto da rimuovere
  };
};