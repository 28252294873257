import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllReviews } from "../api";
import { setAllReviews } from "../context/actions/reviewAction";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const ReviewsSlider = () => {
  const dispatch = useDispatch();
  const reviews = useSelector((state) => state.reviews.reviews || []);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const data = await getAllReviews();
        dispatch(setAllReviews(data));
      } catch (error) {
        console.error("Errore nel recupero delle recensioni:", error);
      }
    };
    fetchReviews();
  }, [dispatch]);

  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
      <span
        key={index}
        className={`text-3xl ${index < rating ? "text-yellow-500" : "text-gray-300"}`}
      >
        ★
      </span>
    ));
  };

  return (
    <div className="p-4 bg-headingColor shadow-lg rounded-xl md:w-full overflow-hidden ">
      <h2 className="text-4xl font-bold mb-4 text-center text-white">Recensioni</h2>
      <p className="text-xl font-medium text-white text-center mb-2">Leggi le Recensioni di chi ha giá visitato la nostra struttura</p>
      <Swiper
        spaceBetween={20}
        slidesPerView={1} // Show 3 reviews at a time
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }} // Autoplay configuration
        modules={[Pagination]} // Pagination module
        className="mySwiper"
      >
        {reviews.slice(0, 3).map((review) => (
          <SwiperSlide
            key={review.id}
            className="bg-lightOverlay p-6 rounded-xl shadow-xl flex justify-center flex-col items-center"
          >
            <h3 className="font-semibold text-2xl mb-2">{review.name}</h3>
            <div className="flex text-3xl mb-2">{renderStars(review.rating)}</div>
            <p className="text-center text-xl text-gray-700">{review.message}</p>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ReviewsSlider;
