import React, { useEffect, useState } from 'react';
import { Header, BookingData } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { setBookings } from '../context/actions/bookingsAction';
import { getAllBookings } from '../api';

const UsersBooking = () => {
  const bookings = useSelector((state) => state.bookings); // Ottieni le prenotazioni dallo store
  const user = useSelector((state) => state.user); // Ottieni l'utente loggato
  const dispatch = useDispatch();

  const [userBookings, setUserBookings] = useState(null); // Stato per le prenotazioni dell'utente

  useEffect(() => {
    // Se non ci sono prenotazioni in Redux, recuperale tramite API
    if (!bookings) {
      getAllBookings().then((data) => {
        dispatch(setBookings(data)); // Salva le prenotazioni in Redux
        // Filtra le prenotazioni per l'utente loggato
        setUserBookings(data.filter((item) => item.userId === user?.user_id));
      });
    } else {
      // Filtra le prenotazioni se sono già presenti in Redux
      setUserBookings(bookings.filter((data) => data.userId === user?.user_id));
    }
  }, [bookings, dispatch, user?.user_id]);

  return (
    <main className='w-screen min-h-screen flex items-center justify-start flex-col bg-primary'>
      <Header />
      <div className='w-full flex flex-col items-center justify-center mt-40 px-6 md:px-24 2xl:px-96 pb-24'>
        {userBookings?.length > 0 ? (
          // Se l'utente ha prenotazioni, mostra la lista
          <>
            <h1 className='bg-headingColor p-3 mb-2 rounded-lg text-white'>Consulta Gli Ordini Effettuati</h1>
            {userBookings.map((item, i) => (
              <BookingData key={i} index={i} data={item} admin={false} />
            ))}
          </>
        ) : (
          // Se l'utente non ha prenotazioni, mostra il messaggio
          <div className="flex flex-col items-center">
            <h1 className='text-2xl text-headingColor font-bold'>
              Nessuna Prenotazione Effettuata 😞
            </h1>
            <p className="text-lg mt-4">
              Non hai ancora effettuato prenotazioni. Dai un'occhiata alle nostre stanze!
            </p>
            <button className="mt-4 bg-textColor text-white px-6 py-2 rounded-md">
              Visiona le Stanze
            </button>
          </div>
        )}
      </div>
    </main>
  );
};

export default UsersBooking;
