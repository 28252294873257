import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from "react-router-dom";
import { validateUserJWTToken } from './api';
import { app } from './config/firebase.config';
import { Main, Login, Dashboard } from "./containers";
import { setUserDetails } from './context/actions/userActions';
import { motion } from 'framer-motion';
import { fadeInOut } from './animations';
import { Alert, CheckOutSuccess, MainLoader, UsersBooking, ScrollToTop } from './components';;


const App = () => {
  const firebaseAuth = getAuth(app);
  const [isLoading, setIsLoading] = useState(true);  // Set iniziale a true per il caricamento
  const alert = useSelector(state => state.alert);

  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      firebaseAuth.onAuthStateChanged(async (cred) => {
        if (cred) {
          const token = await cred.getIdToken();
          const data = await validateUserJWTToken(token);
          if (data) {
            dispatch(setUserDetails(data));
          }
        }
        setIsLoading(false); // Ferma il caricamento solo quando il processo di autenticazione è completato
      });
    };

    checkAuth();
  }, [firebaseAuth, dispatch]); // Assicurati che firebaseAuth e dispatch siano passati come dipendenze

  return (
    <div className='w-screen h-auto flex flex-col items-center justify-center'>
      {/* Visualizza loader durante il caricamento */}
      {isLoading && (
        <motion.div 
          {...fadeInOut} 
          className='fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full'
        >
          <MainLoader />
        </motion.div>
      )}
      
      {/* Rotte della tua applicazione */}
      <ScrollToTop />
      <Routes>
        <Route path='/*' element={<Main />} />
        <Route path='/login' element={<Login />} />
        <Route path='/dashboard/*' element={<Dashboard />} />
        <Route path='/checkout-success' element={<CheckOutSuccess />} />
        <Route path='/user-booking' element={<UsersBooking />} />
      </Routes>

      {/* Mostra l'alert se presente */}
      {alert?.type && <Alert type={alert?.type} message={alert?.message} />}
    </div>
  );
}

export default App;
