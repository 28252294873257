export const privacyPolicy = [
  {
    English: {
      "Who we are": "Our website address is https://bebpiazzadarmi.it",
      Comments: {
        intro:
          "When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.",
        Gravatar:
          "An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment.",
      },
      Media:
        "If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.",
      Cookies: {
        General:
          "If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.",
        "Login Page":
          "If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.",
        Login:
          "When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.",
        "Post Edit":
          "If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.",
      },
      "embedded Content":
        "Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.",
      "Data Sharing":
        "If you request a password reset, your IP address will be included in the reset email.",
      "Data Retenction":
        "If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.",
      "User Rights":
        "If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.",
      "Data Sent":
        "Visitor comments may be checked through an automated spam detection service.",
    },
    Italiano: {
      "Chi Siamo": "Il nostro indirizzo del sito web è: https://bebpiazzadarmi.it",
      Commenti: {
        intro:
          "Quando i visitatori lasciano commenti sul sito, raccogliamo i dati mostrati nel modulo dei commenti, oltre all’indirizzo IP del visitatore e la stringa dell’agente utente del browser per aiutare nella rilevazione dello spam.",
        Gravatar:
          "Una stringa anonimizzata creata dal tuo indirizzo email (chiamata anche hash) potrebbe essere fornita al servizio Gravatar per verificare se lo stai utilizzando. La politica sulla privacy del servizio Gravatar è disponibile qui: https://automattic.com/privacy/. Dopo l’approvazione del tuo commento, la tua immagine del profilo è visibile al pubblico nel contesto del tuo commento.",
      },
      Media:
        "Se carichi immagini sul sito web, dovresti evitare di caricare immagini con dati di posizione incorporati (EXIF GPS). I visitatori del sito web possono scaricare ed estrarre qualsiasi dato di posizione dalle immagini sul sito web.",
      Cookies: {
        Generale:
          "Se lasci un commento sul nostro sito, puoi scegliere di salvare il tuo nome, l’indirizzo email e il sito web nei cookie. Questi sono per la tua comodità in modo che tu non debba riempire i tuoi dettagli nuovamente quando lasci un altro commento. Questi cookie dureranno per un anno.",
        "Pagina Accesso":
          "Se visiti la nostra pagina di accesso, verrà impostato un cookie temporaneo per determinare se il tuo browser accetta i cookie. Questo cookie non contiene dati personali e viene eliminato quando chiudi il tuo browser.",
        Accesso:
          "Quando effettui l’accesso, impostiamo anche diversi cookie per salvare le informazioni di accesso e le tue scelte di visualizzazione dello schermo. I cookie di accesso durano due giorni e i cookie delle opzioni dello schermo durano un anno. Se selezioni “Ricordami”, il tuo accesso persistirà per due settimane. Se esci dal tuo account, i cookie di accesso verranno rimossi.",
        Modifica:
          "Se modifichi o pubblichi un articolo, verrà salvato un cookie aggiuntivo nel tuo browser. Questo cookie non contiene dati personali e indica semplicemente l’ID del post dell’articolo appena modificato. Scade dopo 1 giorno.",
      },
      "Contenuti":
        "Gli articoli su questo sito possono includere contenuti incorporati (ad esempio video, immagini, articoli, ecc.). I contenuti incorporati da altri siti web si comportano esattamente come se il visitatore avesse visitato l’altro sito web.",
      "Condivisione Dati":
        "Se richiedi un ripristino della password, il tuo indirizzo IP verrà incluso nella email di ripristino.",
      "Conservazione Dei Dati":
        "Se lasci un commento, il commento e i suoi metadati vengono conservati indefinitamente. Questo è fatto per poter riconoscere e approvare automaticamente eventuali commenti successivi anziché tenerli in una coda di moderazione.",
      "Diritti dell'utente":
        "Se hai un account su questo sito o hai lasciato commenti, puoi richiedere di ricevere un file esportato dei dati personali che abbiamo su di te, compresi i dati che ci hai fornito. Puoi anche richiedere che cancelliamo tutti i dati personali che abbiamo su di te. Ciò non include i dati che siamo obbligati a conservare per scopi amministrativi, legali o di sicurezza.",
      "Invio Dati":
        "I commenti dei visitatori possono essere controllati attraverso un servizio automatico di rilevamento dello spam.",
    },
  },
];
