import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


const DBHome = () => {
 
  return (
    <div className='flex items-center justify-center flex-col pt-6 w-full h-full'>
        <div className='px-2 w-full text-center bg-headingColor shadow-xl py-2 rounded-lg border-b-2 border-textColor'>
          <h1 className='text-2xl font-medium text-primary'>
            Dashboard
          </h1>
          <p className="text-sm text-primary">
            Benvenuto nel Pannello di Controllo
          </p>
        </div>
    </div>
  )
}

export default DBHome