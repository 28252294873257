import React from "react";
import { Route, Routes } from "react-router-dom";
import { DBHome, DBBookings, DBRooms, DBUsers, DBNewItem, DBContactForm, DBReviews } from ".";

const DBRightSection = () => {
  return (
    <div className="px-4 flex flex-col py-4 flex-1 h-full">
      <div className="flex flex-col flex-1 overflow-y-scroll scrollbar-none">
        <Routes>
          <Route path="/home" element={<DBHome />} />
          <Route path="/bookings" element={<DBBookings />} />
          <Route path="/rooms" element={<DBRooms />} />
          <Route path="/create-room" element={<DBNewItem />} />
          <Route path="/users" element={<DBUsers />} />
          <Route path="/contacts" element={<DBContactForm />} />
          <Route path="/reviews" element={<DBReviews />} />
        </Routes>
      </div>
    </div>
  );
};

export default DBRightSection;
