import React, { useState, useRef } from "react";
import "react-image-lightbox/style.css"; // Importa gli stili del lightbox
import Lightbox from "react-image-lightbox";

const GalleryStruttura = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImageIndex, setLightboxImageIndex] = useState(0);
  const imageRefs = useRef([]);
  const containerRef = useRef(null);

  const images = [
    {
      url: "https://via.placeholder.com/1080",
      alt: "Placeholder Image 1",
      title: "Foto 1",
    },
    {
      url: "https://via.placeholder.com/256",
      alt: "Placeholder Image 2",
      title: "Foto 2",
    },
    {
      url: "https://via.placeholder.com/1080",
      alt: "Placeholder Image 3",
      title: "Foto 3",
    },
    {
      url: "https://via.placeholder.com/256",
      alt: "Placeholder Image 4",
      title: "Foto 4",
    },
    {
      url: "https://via.placeholder.com/1080",
      alt: "Placeholder Image 5",
      title: "Foto 5",
    },
    {
      url: "https://via.placeholder.com/256",
      alt: "Placeholder Image 6",
      title: "Foto 6",
    },
    {
      url: "https://via.placeholder.com/1080",
      alt: "Placeholder Image 7",
      title: "Foto 7",
    },

    // Aggiungi altre immagini se necessario
  ];

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : images.length - 1;
      containerRef.current.scrollBy({
        left: -imageRefs.current[0].offsetWidth,
        behavior: "smooth",
      });
      return newIndex;
    });
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex < images.length - 1 ? prevIndex + 1 : 0;
      containerRef.current.scrollBy({
        left: imageRefs.current[0].offsetWidth,
        behavior: "smooth",
      });
      return newIndex;
    });
  };

  const openLightbox = (index) => {
    setLightboxImageIndex(index);
    setIsLightboxOpen(true);
  };

  return (
    <div className="w-full flex flex-col items-start">
      <div className="flex flex-col items-start justify-start gap-1">
        <p className="text-2xl text-headingColor font-bold">
          Foto Gallery Struttura
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis
          excepturi explicabo itaque nam enim eos iusto officiis molestias
          tenetur reiciendis!
        </p>
      </div>
      <div className="relative w-full overflow-hidden mb-15">
        {currentIndex > 0 && (
          <div className="absolute top-1/2 left-4 transform -translate-y-1/2 z-20 hidden md:block">
            <button
              onClick={handlePrevClick}
              className="bg-white rounded-full w-12 h-12 flex items-center justify-center shadow-lg transition duration-300 ease-in-out transform hover:bg-gray-200 hover:scale-105"
            >
              {/* Freccia sinistra SVG */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-8 h-8"
              >
                <path d="M15 18l-6-6 6-6v12z" />
              </svg>
            </button>
          </div>
        )}
        <div
          ref={containerRef}
          className="relative w-full flex overflow-x-auto scrollbar-none py-8"
        >
          <div className="flex space-x-6">
            {images.map((image, i) => (
              <SliderCard
                key={i}
                image={image}
                index={i}
                onClick={() => openLightbox(i)} // Apri il lightbox al click
                ref={(el) => (imageRefs.current[i] = el)}
              />
            ))}
          </div>
        </div>
        {currentIndex < images.length - 1 && (
          <div className="absolute top-1/2 right-4 transform -translate-y-1/2 z-20 hidden md:block">
            <button
              onClick={handleNextClick}
              className="bg-white rounded-full w-12 h-12 flex items-center justify-center shadow-lg transition duration-300 ease-in-out transform hover:bg-gray-200 hover:scale-105"
            >
              {/* Freccia destra SVG */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-8 h-8"
              >
                <path d="M9 18l6-6-6-6v12z" />
              </svg>
            </button>
          </div>
        )}
      </div>

      {/* Lightbox */}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[lightboxImageIndex].url}
          nextSrc={images[(lightboxImageIndex + 1) % images.length].url}
          prevSrc={
            images[(lightboxImageIndex + images.length - 1) % images.length].url
          }
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setLightboxImageIndex(
              (lightboxImageIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxImageIndex((lightboxImageIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

const SliderCard = React.forwardRef(({ image, index, onClick }, ref) => {
  return (
    <div
      className="w-64 cursor-pointer rounded-md bg-headingColor shadow-md flex flex-col items-center justify-center gap-4"
      onClick={onClick}
      ref={ref}
    >
      <img
        src={image.url}
        alt={image.alt}
        className="w-full h-48 object-cover rounded-md"
      />
      <p className="text-xl font-semibold text-primary">{image.title}</p>
    </div>
  );
});

export default GalleryStruttura;
