export const termsAndConditions = `
    <h1>Termini e Condizioni</h1>
    <p><strong>Ultimo aggiornamento:</strong> Novembre 30, 2024</p>
    
    <h2>1. Introduzione</h2>
    <p>Questi Termini e Condizioni si applicano a questo sito web e alle transazioni relative ai nostri prodotti e servizi. Può essere vincolato da ulteriori contratti relativi alla tua relazione con noi o a qualsiasi prodotto o servizio che ricevi da noi. Se qualsiasi disposizione dei contratti aggiuntivi è in conflitto con qualsiasi disposizione di questi Termini, le disposizioni di questi contratti aggiuntivi controlleranno e prevarranno.</p>

    <h2>2. Vincolo</h2>
    <p>Registrandosi, accedendo o utilizzando in altro modo questo sito web, l’utente accetta di essere vincolato da questi Termini e condizioni di seguito riportati. Il semplice uso di questo sito web implica la conoscenza e l’accettazione di questi termini e condizioni. In alcuni casi particolari, possiamo anche chiederle di acconsentire esplicitamente.</p>

    <h2>3. Comunicazione elettronica</h2>
    <p>Utilizzando questo sito web o comunicando con noi per via elettronica, lei accetta e riconosce che possiamo comunicare con lei elettronicamente sul nostro sito web o inviandole un’e-mail, e accetta che tutti gli accordi, gli avvisi, le divulgazioni e le altre comunicazioni che le forniamo elettronicamente soddisfino qualsiasi requisito legale, incluso ma non limitato al requisito che tali comunicazioni debbano essere in forma scritta.</p>

    <h2>4. Proprietà intellettuale</h2>
    <p>Noi o i nostri licenziatari possediamo e controlliamo tutti i diritti d’autore e gli altri diritti di proprietà intellettuale del sito web e i dati, le informazioni e le altre risorse visualizzate o accessibili all’interno del sito web.</p>

    <h3>4.1 Tutti i diritti sono riservati</h3>
    <p>A meno che il contenuto specifico non imponga diversamente, all’utente non viene concessa una licenza o qualsiasi altro diritto in base a diritti d’autore, marchi, brevetti o altri diritti di proprietà intellettuale. Ciò significa che non potrete utilizzare, copiare, riprodurre, eseguire, mostrare, distribuire, incorporare in qualsiasi mezzo elettronico, alterare, decompilare, trasferire, scaricare, trasmettere, monetizzare, vendere o commercializzare qualsiasi risorsa di questo sito web in qualsiasi forma, senza il nostro previo permesso scritto, tranne e solo nella misura in cui sia diversamente stabilito in regolamenti di legge obbligatori (come il diritto di citazione).</p>

    <h2>5. Proprietà di terzi</h2>
    <p>Il nostro sito web può includere collegamenti ipertestuali o altri riferimenti a siti web di altre parti. Non controlliamo o rivediamo il contenuto dei siti web di altre parti che sono collegati a questo sito web. I prodotti o i servizi offerti da altri siti web sono soggetti ai Termini e Condizioni applicabili di queste terze parti. Le opinioni espresse o il materiale che appare su questi siti web non sono necessariamente condivise o approvate da noi.</p>

    <p>Non saremo responsabili delle pratiche di privacy o del contenuto di questi siti. Lei sopporta tutti i rischi associati all’uso di questi siti web e di qualsiasi servizio di terzi collegato. Non accetteremo alcuna responsabilità per eventuali perdite o danni in qualsiasi modo, comunque causati, derivanti dalla vostra divulgazione a terzi di informazioni personali.</p>

    <h2>6. Utilizzo responsabile</h2>
    <p>Visitando il nostro sito web, l’utente accetta di utilizzarlo solo per gli scopi previsti e come consentito da queste Condizioni, da qualsiasi contratto aggiuntivo con noi, e dalle leggi e dai regolamenti applicabili, nonché dalle pratiche online generalmente accettate e dalle linee guida del settore. Non può utilizzare il nostro sito web o i nostri servizi per utilizzare, pubblicare o distribuire qualsiasi materiale che consiste in (o è collegato a) software per computer dannosi; utilizzare i dati raccolti dal nostro sito web per qualsiasi attività di marketing diretto, o condurre qualsiasi attività di raccolta dati sistematica o automatizzata su o in relazione al nostro sito web.</p>

    <p>È severamente vietato intraprendere qualsiasi attività che causi, o possa causare, danni al sito web o che interferisca con le prestazioni, la disponibilità o l’accessibilità del sito web.</p>

    <h2>7. Presentazione di idee</h2>
    <p>Non presentare idee, invenzioni, opere d’autore o altre informazioni che possono essere considerate proprietà intellettuale propria che vorresti presentarci, a meno che non abbiamo prima firmato un accordo sulla proprietà intellettuale o un accordo di non divulgazione. Se ce lo riveli in assenza di tale accordo scritto, ci concedi una licenza mondiale, irrevocabile, non esclusiva, priva di royalty per utilizzare, riprodurre, memorizzare, adattare, pubblicare, tradurre e distribuire il tuo contenuto in qualsiasi media esistente o futuro.</p>

    <h2>8. Cessazione dell’uso</h2>
    <p>Possiamo, a nostra sola discrezione, in qualsiasi momento modificare o interrompere l’accesso, temporaneamente o permanentemente, al sito web o a qualsiasi servizio su di esso. Lei accetta che non saremo responsabili nei Suoi confronti o nei confronti di terzi per qualsiasi modifica, sospensione o interruzione del Suo accesso o uso del sito web o di qualsiasi contenuto che Lei possa aver condiviso sul sito web. Non avrà diritto ad alcun indennizzo o altro pagamento, anche se alcune caratteristiche, impostazioni, e/o qualsiasi Contenuto che lei ha contribuito o su cui ha fatto affidamento, sono permanentemente persi. Non può eludere o bypassare, o tentare di eludere o bypassare, qualsiasi misura di restrizione di accesso sul nostro sito web.</p>

    <h2>9. Garanzie e responsabilità</h2>
    <p>Niente in questa sezione limiterà o escluderà qualsiasi garanzia implicita per legge che sarebbe illegale limitare o escludere. Questo sito web e tutti i contenuti del sito web sono forniti su una base "così com'è" e "come disponibile" e possono includere imprecisioni o errori tipografici. Decliniamo espressamente tutte le garanzie di qualsiasi tipo, sia espresse che implicite, per quanto riguarda la disponibilità, l’accuratezza o la completezza del Contenuto. Non garantiamo che:</p>

    <ul>
        <li>questo sito web o i nostri contenuti soddisfino le vostre esigenze;</li>
        <li>questo sito web sarà disponibile in modo ininterrotto, tempestivo, sicuro o senza errori.</li>
    </ul>

    <p>Nulla di questo sito web costituisce o è destinato a costituire una consulenza legale, finanziaria o medica di qualsiasi tipo. Se avete bisogno di consigli dovreste consultare un professionista appropriato.</p>

    <h2>10. Privacy</h2>
    <p>Per accedere al nostro sito web e/o ai nostri servizi, ti può essere richiesto di fornire alcune informazioni su di te come parte del processo di registrazione. L’utente accetta che qualsiasi informazione fornita sia sempre accurata, corretta e aggiornata.</p>

    <p>Abbiamo sviluppato una politica per affrontare qualsiasi preoccupazione sulla privacy che potresti avere. Per ulteriori informazioni, si prega di consultare la nostra Privacy Statement e la nostra Cookie Policy.</p>

    <h2>11. Accessibilità</h2>
    <p>Ci impegniamo a rendere il contenuto che forniamo accessibile agli individui con disabilità. Se hai una disabilità e non sei in grado di accedere a qualsiasi parte del nostro sito web a causa della tua disabilità, ti chiediamo di darci una notifica includendo una descrizione dettagliata del problema che hai incontrato. Se il problema è facilmente identificabile e risolvibile in conformità con gli strumenti e le tecniche informatiche standard del settore, lo risolveremo prontamente.</p>

    <h2>12. Restrizioni all’esportazione / Conformità legale</h2>
    <p>L’accesso al sito web da territori o paesi in cui il Contenuto o l’acquisto di prodotti o Servizi venduti sul sito web è illegale è proibito. Non può utilizzare questo sito web in violazione delle leggi e dei regolamenti sull’esportazione di Italia.</p>

    <h2>13. Legge applicabile e risoluzione delle controversie</h2>
    <p>Questi Termini e Condizioni sono regolati dalla legge italiana. Qualsiasi controversia che derivi da questi Termini e Condizioni sarà risolta in modo esclusivo dal foro competente della città di Milano.</p>

    <h2>14. Modifiche a questi Termini e Condizioni</h2>
    <p>Possiamo aggiornare periodicamente questi Termini e Condizioni e modifiche entreranno in vigore appena pubblicate su questo sito. Ti invitiamo a controllare regolarmente per essere a conoscenza di eventuali modifiche.</p>

    <p>Ultimo aggiornamento: Novembre 30, 2024.</p>
`;

