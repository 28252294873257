import React, { useState } from 'react';
import { HiCurrencyEuro } from '../assets/icons';
import { updateRoom } from '../api'; // Funzione per aggiornare la stanza
import { alertNull, alertSuccess } from '../context/actions/alertAction';
import { useDispatch } from 'react-redux';

const EditRoomForm = ({ room, onClose }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    roomName: room.roomName,
    roomType: room.roomType,
    pricePerNight: room.pricePerNight,
    maxGuests: room.maxGuests,
    description: room.description,
    amenities: room.amenities.join(', '), // Unisci le amenità in una stringa
    availability: {
      startDate: room.availability.startDate,
      endDate: room.availability.endDate,
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedRoom = {
      ...room,
      ...formData,
      amenities: formData.amenities.split(',').map(amenity => amenity.trim()), // Ritorna un array di amenità
    };
    
    updateRoom(updatedRoom).then(() => {
      dispatch(alertSuccess('Stanza Aggiornata con Successo'));
      setTimeout(() => {
        dispatch(alertNull());
      }, 3000);
      onClose(); // Chiudi il modulo dopo l'aggiornamento
    });
  };

  return (
    <div className='z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
      <form onSubmit={handleSubmit} className='bg-white p-6 rounded-lg shadow-lg max-w-xl m-4'>
        <h2 className='text-xl font-semibold mb-4 text-center'>Modifica Stanza</h2>
        <div className='mb-4'>
          <label className='block mb-1'>Nome</label>
          <input
            type='text'
            name='roomName'
            value={formData.roomName}
            onChange={handleChange}
            className='border rounded px-3 py-2 w-full'
            required
          />
        </div>
        <div className='mb-4'>
          <label className='block mb-1'>Tipo</label>
          <input
            type='text'
            name='roomType'
            value={formData.roomType}
            onChange={handleChange}
            className='border rounded px-3 py-2 w-full'
            required
          />
        </div>
        <div className='mb-4'>
          <label className='block mb-1'>Prezzo a Notte</label>
          <div className='flex items-center'>
            <HiCurrencyEuro className='text-lg' />
            <input
              type='number'
              name='pricePerNight'
              value={formData.pricePerNight}
              onChange={handleChange}
              className='border rounded px-3 py-2 w-full ml-2'
              required
            />
          </div>
        </div>
        <div className='mb-4'>
          <label className='block mb-1'>Ospiti Massimi</label>
          <input
            type='number'
            name='maxGuests'
            value={formData.maxGuests}
            onChange={handleChange}
            className='border rounded px-3 py-2 w-full'
            required
          />
        </div>
        <div className='mb-4'>
          <label className='block mb-1'>Descrizione</label>
          <textarea
            name='description'
            value={formData.description}
            onChange={handleChange}
            className='border rounded px-3 py-2 w-full'
            required
          ></textarea>
        </div>
        <div className='mb-4'>
          <label className='block mb-1'>Servizi (separati da virgola)</label>
          <input
            type='text'
            name='amenities'
            value={formData.amenities}
            onChange={handleChange}
            className='border rounded px-3 py-2 w-full'
            required
          />
        </div>
        <div className='mb-4'>
          <label className='block mb-1'>Disponibilità Dal</label>
          <input
            type='date'
            name='startDate'
            value={formData.availability.startDate.slice(0, 10)} // Formato YYYY-MM-DD
            onChange={handleChange}
            className='border rounded px-3 py-2 w-full'
            required
          />
        </div>
        <div className='mb-4'>
          <label className='block mb-1'>Disponibilità Al</label>
          <input
            type='date'
            name='endDate'
            value={formData.availability.endDate.slice(0, 10)} // Formato YYYY-MM-DD
            onChange={handleChange}
            className='border rounded px-3 py-2 w-full'
            required
          />
        </div>
        <div className='flex justify-between'>
          <button type='button' onClick={onClose} className='bg-gray-400 text-white px-4 py-2 rounded-md'>Annulla</button>
          <button type='submit' className='bg-blue-500 text-white px-4 py-2 rounded-md'>Aggiorna</button>
        </div>
      </form>
    </div>
  );
};

export default EditRoomForm;
