import React from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa"; // Icône per telefono, email e mappa
import { motion } from "framer-motion";
import { ContactForm, Faq } from "../components";

const Contact = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
      className="min-h-screen bg-textColor rounded-xl py-16 px-6 md:px-24 2xl:px-96"
    >
      {/* Titolo */}
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold text-gray-800">Contattaci</h2>
        <p className="text-lg text-white mt-2">
          Siamo qui per aiutarti, inviaci un messaggio e ti risponderemo al più
          presto!
        </p>
      </div>

      {/* Contenitore per contatti e mappa */}
      <div className="flex flex-col md:flex-row gap-12 justify-between items-center">
        {/* Sezione Contatti */}
        <div className="flex flex-col items-start space-y-8">
          <div className="flex items-center space-x-4">
            <FaPhoneAlt className="text-white text-3xl" />
            <div>
              <h3 className="text-xl font-semibold text-gray-800">Telefono</h3>
              <a href="tel:+39123456789" className="text-lg text-white">
                +39 123 456 789
              </a>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <FaEnvelope className="text-white text-3xl" />
            <div>
              <h3 className="text-xl font-semibold text-gray-800">Email</h3>
              <p className="text-lg text-white">info@bebpiazzadarmi.it</p>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <FaMapMarkerAlt className="text-white text-3xl" />
            <div>
              <h3 className="text-xl font-semibold text-gray-800">Indirizzo</h3>
              <p className="text-lg text-white">
                Piazza Mazzini, Lamezia Terme (CZ), Italia
              </p>
            </div>
          </div>
        </div>

        {/* Sezione Mappa di Google */}
        <div className="w-full md:w-1/2 h-96">
          <iframe
            className="rounded-md"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d775.4573518679225!2d16.31591146963683!3d38.973563140855354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133fe56c03e88097%3A0xf5e8e2eac5b72bff!2sPiazza%20Mazzini%2C%2050%2C%2088046%20Lamezia%20Terme%20CZ!5e0!3m2!1sit!2sit!4v1731426334071!5m2!1sit!2sit"
            width="400"
            height="350"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      {/* Modulo di Contatto */}
     
      <div className="bg-white p-6 rounded-lg mt-16 max-w-2xl mx-auto">
        <Faq />
      </div>

      <div className="mt-16 max-w-2xl mx-auto">
        <ContactForm />
      </div>
    </motion.div>
  );
};

export default Contact;
