import React from "react";
import { termsAndConditions } from "../utils/termsConditions";

const TermsConditions = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">Termini e Condizioni</h1>
      <div
        className="prose prose-sm sm:prose lg:prose-lg text-gray-700"
        dangerouslySetInnerHTML={{
          __html: termsAndConditions,
        }}
      />
    </div>
  );
};

export default TermsConditions;
