import React from "react";
import { useSelector } from "react-redux";
import RoomCard from "./RoomCard";
import { ReviewsSlider, GallerySection, ContactForm, Faq } from "../components";

const Room = () => {
  const rooms = useSelector((state) => state.rooms.rooms || []);

  return (
    <div className="p-6 rounded-xl bg-lightOverlay min-h-screen max-w-full">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className=" bg-white py-10 px-6 rounded-lg shadow-lg mb-8">
          <h1 className="text-3xl md:text-4xl font-medium mb-1 text-headingColor">
            Menu delle Stanze
          </h1>
          <p className="text-lg md:text-xl font-normal">
            Scopri le nostre splendide camere, progettate per offrirti il
            massimo comfort durante il tuo soggiorno.
          </p>
        </div>

        {/* Intro Paragraph */}
        <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-6">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus
          aliquid neque tempore repudiandae dicta odit, quod provident quisquam.
          Beatae, nisi! Le nostre stanze sono state accuratamente arredate per
          garantire un’esperienza unica e indimenticabile.
        </p>

        {/* Room Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
          {rooms.length > 0 ? (
            rooms.map((room) => <RoomCard key={room.roomId} room={room} />)
          ) : (
            <p className="col-span-full text-center text-gray-600 text-lg">
              Nessuna stanza disponibile
            </p>
          )}
        </div>
      </div>
      <div className="mb-16 flex text-center justify-center">
        <ReviewsSlider />
      </div>
      <div className="mb-16">
        <GallerySection />
      </div>
      <div className="mb-16">
        <Faq />
      </div>
      <div className="mb-16">
        <ContactForm />
      </div>
    </div>
  );
};

export default Room;
