import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAllContacts, removeContact } from "../context/actions/contactAction"; // Importa l'azione per rimuovere un contatto
import { getAllContacts, deleteContact } from "../api"; // Importa la funzione per ottenere i contatti
import { MainLoader } from "../components"; // Componente Loader se necessario

const DBContactForm = () => {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts.contacts || []); // Recupera i contatti dallo store Redux (default vuoto array)
  const [loading, setLoading] = useState(true);

  // Funzione per eliminare un contatto
  const handleDelete = async (id) => {
    // Conferma prima di eliminare
    const confirmDelete = window.confirm("Sei sicuro di voler eliminare questo contatto?");
    if (confirmDelete) {
      const success = await deleteContact(id); // Chiamata API per eliminare il contatto
      if (success) {
        // Se l'eliminazione ha successo, rimuoviamo il contatto dal Redux store
        dispatch(removeContact(id)); // Aggiorna lo stato Redux per rimuovere il contatto eliminato
      }
    }
  };

  useEffect(() => {
    if (contacts.length === 0) {  // Solo se i contatti non sono già nel Redux store
      setLoading(true);
      getAllContacts()
        .then((data) => {
          dispatch(setAllContacts(data)); // Salva i contatti nel Redux store
          setLoading(false); // Imposta il loading a false quando i dati sono pronti
        })
        .catch((error) => {
          console.error("Errore nel caricamento dei contatti:", error);
          setLoading(false); // Imposta il loading a false in caso di errore
        });
    } else {
      setLoading(false); // Se i contatti sono già presenti, non serve fare il fetch
    }
  }, [contacts, dispatch]); // Esegui il fetch solo se i contatti non sono già presenti nel Redux store

  if (loading) {
    return <MainLoader />; // Mostra il loader mentre i dati vengono caricati
  }

  return (
    <div className="space-y-4">
      {contacts.length > 0 ? (
        contacts.map((contact) => (
          <div key={contact.id} className="bg-primary shadow-md p-4 rounded-xl mt-4">
            <div className="flex justify-between">
              <span>{contact.name}</span>
              <span className="text-sm text-gray-500">
                {new Date(contact.createdAt._seconds * 1000).toLocaleString()}
              </span>
            </div>
            <div className="mt-4 space-y-2 rounded-xl">
              <p><strong>Email:</strong> {contact.email}</p>
              <p><strong>Telefono:</strong> {contact.phone}</p>
              <p><strong>Messaggio:</strong> {contact.message}</p>
              <button
                onClick={() => handleDelete(contact.id)} // Chiama handleDelete con l'id del contatto
                className="mt-2 px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
              >
                Elimina
              </button>
            </div>
          </div>
        ))
      ) : (
        <div>No contacts available.</div>
      )}
    </div>
  );
};

export default DBContactForm;
