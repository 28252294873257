import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Avatar, Logo } from '../assets';
import { isActiveStyles, isNotActiveStyles } from '../utils/styles';
import { motion } from 'framer-motion';
import { buttonClick, slideTop } from '../animations';
import { FaUserCircle, ImCross, MdLogout, RxHamburgerMenu } from '../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'firebase/auth';
import { app } from '../config/firebase.config';
import { setUserNull } from '../context/actions/userActions';

const Header = () => {
    const user = useSelector(state => state.user);

    const handleMenuToggle = () => {
        setIsMenu(!isMenu);
    };

    const [isMenu, setIsMenu] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const firebaseAuth = getAuth(app);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const signOut = () => {
        firebaseAuth
            .signOut()
            .then(() => {
                dispatch(setUserNull());
                navigate("/login", { replace: true });
            })
            .catch((err) => console.log(err))
    };

    return (
        <header
            className='bg-primary w-full shadow-md flex justify-around py-2 rounded-b-2xl fixed z-20'
        >
            <NavLink to={"/"} className="flex items-center justify-center gap-4">
                <img src={Logo} alt="logo" className='w-20' />
            </NavLink>

            <nav className='flex items-center justify-center gap-2'>
                <ul className='hidden md:flex items-center justify-center gap-4'>
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? isActiveStyles : isNotActiveStyles
                        }
                        to={"/"}
                    >
                        Home
                    </NavLink>
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? isActiveStyles : isNotActiveStyles
                        }
                        to={"/stanze"}
                    >
                        Stanze
                    </NavLink>
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? isActiveStyles : isNotActiveStyles
                        }
                        to={"/gallery"}
                    >
                        Gallery
                    </NavLink>
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? isActiveStyles : isNotActiveStyles
                        }
                        to={"/contatti"}
                    >
                        Contatti
                    </NavLink>
                </ul>
            </nav>
            <motion.div
                className='flex items-center gap-4'
            >
                <motion.div
                    {...buttonClick}
                    className='relative cursor-pointer flex gap-4 items-baseline'
                >
                    {user ? (
                        <>
                            <div
                                className='relative cursor-pointer'
                                onMouseEnter={() => setIsMenu(true)}
                                onClick={handleMenuToggle}
                            >
                                <div
                                    className='w-8 h-8 rounded-full shadow-md cursor-pointer overflow-hidden flex items-start justify-center'
                                >
                                    <motion.img
                                        className='w-full h-full object-cover'
                                        src={user?.picture ? user.picture : Avatar}
                                        whileHover={{ scale: 1.15 }}
                                        referrerPolicy='no-referrer'
                                    />
                                </div>

                                {isMenu && (
                                    <motion.div
                                        {...slideTop}
                                        onMouseLeave={() => setIsMenu(false)}
                                        className='px-6 py-4 bg-lightOverlay bg-white rounded-md shadow-md absolute z-50 top-12 right-0 flex flex-col gap-4'
                                    >
                                        {user?.user_id === process.env.REACT_APP_ADMIN_ID && (
                                            <Link
                                                className='p-2 hover:rounded-md hover:bg-headingColor text-xl hover:text-white hover:scale-105 text-headingColor'
                                                to={"/dashboard/home"}
                                            >
                                                Dashboard
                                            </Link>
                                        )}

                                        <Link
                                            className='p-2 hover:rounded-md hover:bg-headingColor text-xl hover:text-white hover:scale-105 text-textColor'
                                            to={"/user-booking"}
                                        >
                                            Ordini
                                        </Link>

                                        <motion.div
                                            {...buttonClick}
                                            onClick={signOut}
                                            className='group flex items-center justify-center px-3 py-2 hover:rounded-md hover:bg-headingColor text-xl hover:text-white hover:scale-105 text-textColor gap-3'
                                        >
                                            <MdLogout className='text-2xl group-hover::text-headingColor' />
                                            <p className='text-textColor text-xl group-hover:text-white'>
                                                Disconnetti
                                            </p>
                                        </motion.div>
                                    </motion.div>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <NavLink to={"/login"}>
                                <motion.button
                                    {...buttonClick}
                                >
                                    <FaUserCircle className='text-2xl text-textColor cursor-pointer' />
                                </motion.button>
                            </NavLink>
                        </>
                    )}
                </motion.div>
                <motion.div
                    className="lg:hidden flex items-center justify-between">
                    <nav>
                        <motion.div
                            className="space-y-2"
                            onClick={() => setIsNavOpen((prev) => !prev)}
                        >
                            <RxHamburgerMenu className='text-3xl' />
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, x: '100%' }}
                            animate={{ opacity: isNavOpen ? 1 : 0, x: isNavOpen ? 0 : '100%' }}
                            transition={{ duration: 0.5 }}
                            className={isNavOpen ? "showMenuNav" : "hideMenuNav"}
                        >
                            <motion.div
                                className="CROSS-ICON absolute top-0 right-0 px-8 py-8"
                                onClick={() => setIsNavOpen(false)}
                            >
                                <ImCross className='text-3xl' />
                            </motion.div>
                            <NavLink to={"/"} className="flex items-center justify-center gap-4">
                                <img src={Logo} alt="logo" className='w-20' />
                            </NavLink>
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? isActiveStyles : isNotActiveStyles
                                }
                                onClick={() => setIsNavOpen(false)}
                                to={"/"}
                            >
                                Home
                            </NavLink>
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? isActiveStyles : isNotActiveStyles
                                }
                                onClick={() => setIsNavOpen(false)}
                                to={"/stanze"}
                            >
                                Stanze
                            </NavLink>
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? isActiveStyles : isNotActiveStyles
                                }
                                onClick={() => setIsNavOpen(false)}
                                to={"/gallery"}
                            >
                                Gallery
                            </NavLink>
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? isActiveStyles : isNotActiveStyles
                                }
                                onClick={() => setIsNavOpen(false)}
                                to={"/contatti"}
                            >
                                Contatti
                            </NavLink>
                        </motion.div>
                    </nav>
                    <style>
                        {`
                            .hideMenuNav {
                                display: none;
                            }
                            .showMenuNav {
                                display: block;
                                position: absolute;
                                width: 100%;
                                height: 50vh;
                                top: 0;
                                right: 0;
                                background: white;
                                z-index: 10;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                gap: 20px;
                                align-items: center;
                            }`
                        }
                    </style>
                </motion.div>
            </motion.div>
        </header>
    )
}

export default Header;
