const initialState = {
    reviews: [], // Stato iniziale con un array vuoto di contatti
  };
  
  const reviewReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_ALL_REVIEWS":
        return {
          ...state,
          reviews: action.reviews, // Imposta i contatti ricevuti nell'array contacts
        };
        case "REMOVE_REVIEWS":
        return {
          ...state,
          reviews: state.reviews.filter((reviews) => reviews.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default reviewReducer;
  