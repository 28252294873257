const initialState = {
    rooms: [],
};

const roomReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ALL_ROOMS":
            return {
                ...state,
                rooms: action.rooms,
            };
        case "ADD_ROOM":
            return {
                ...state,
                rooms: [...state.rooms, action.room],
            };
        case "REMOVE_ROOM":
            return {
                ...state,
                rooms: state.rooms.filter(room => room.roomId !== action.roomId),
            };
        default:
            return state;
    }
};

export default roomReducer;
