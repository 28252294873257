import React from "react";
import { privacyPolicy } from "../utils/privacyPolicy";

const PrivacyPolicy = () => {
  return (
    <div className="p-6 bg-gray-50 min-h-screen rounded-lg shadow-md">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Privacy Policy</h1>
      {privacyPolicy.map((policy, index) => (
        <div key={index} className="mb-6">
          {Object.keys(policy).map((language) => (
            <div key={language} className="mb-4">
              <h2 className="text-3xl font-semibold text-gray-700 mb-2">{language}</h2>
              <ul className="list-disc pl-5 space-y-3 text-gray-600">
                {Object.entries(policy[language]).map(([key, value]) => {
                  // Verifica se la proprietà è un oggetto (come comments, cookies, etc.)
                  if (typeof value === "object") {
                    return (
                      <li key={key}>
                        <div className="font-medium">{key}:</div>
                        <ul className="list-inside pl-5 space-y-2">
                          {Object.entries(value).map(([subKey, subValue]) => (
                            <li key={subKey} className="ml-5">
                              <span className="font-semibold">{subKey}:</span> {subValue}
                            </li>
                          ))}
                        </ul>
                      </li>
                    );
                  }
                  return (
                    <li key={key} className="ml-5">
                      <span className="font-semibold">{key}:</span> {value}
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PrivacyPolicy;
