import {combineReducers} from "redux";
import userReducer from "./userReducer";
import alertReducer from "./alertReducer";
import allUserReducer from "./allUserReducer";
import bookingsReducer from "./bookingsReducer";
import roomReducer from "./roomReducer";
import contactReducer from './contactReducer';
import reviewReducer from './reviewReducer';


const myReducers = combineReducers ({
    user: userReducer,
    alert: alertReducer,
    allUsers: allUserReducer,
    bookings: bookingsReducer,
    rooms: roomReducer,
    contacts: contactReducer,
    reviews: reviewReducer,
});

export default myReducers;