import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import it from "date-fns/locale/it";
import Lightbox from "react-image-lightbox";
import axios from "axios"; // Importa axios
import { useSelector } from "react-redux";
import { baseURL } from "../api";
import { ReviewsSlider } from '../components'
 // Importa useSelector per accedere ai dati di Redux

const RoomSection = ({
  roomId,
  roomName,
  description,
  roomType,
  pricePerNight,
  maxGuests,
  amenities,
  images,
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Recupera i dati dell'utente dallo stato di Redux
  const userData = useSelector((state) => state.user);
  const roomData = useSelector((state) =>
    state.rooms.rooms.find((room) => room.roomId === roomId)
  );
  
  const numberOfNights = Math.max(
    1,
    Math.ceil(
      (range[0].endDate - range[0].startDate) / (1000 * 60 * 60 * 24)
    )
  );// Supponiamo che l'utente sia memorizzato in state.user

  const formatDate = (date) => {
    return format(date, "dd-MM-yyyy"); // Formatta la data come "giorno-mese-anno"
  };


  // Funzione per inviare la prenotazione
  const handleBooking = async () => {
    try {
      // Calcoliamo le date e il totale della prenotazione
      const startDate = formatDate(new Date(range[0].startDate));
      const endDate = formatDate(new Date(range[0].endDate));
      const totalAmount = roomData.pricePerNight * numberOfNights;
      
      // Crea l'oggetto metadata con le informazioni necessarie per Stripe
      const metadata = {
        userId: userData.uid,
        roomName: roomData.roomName,
        roomType: roomData.roomType,
        totalAmount,
        startDate,
        endDate,
        maxGuests: roomData.maxGuests,
      };
    
      // Crea il payload da inviare al server
      const payload = {
        userId: userData.uid,
        roomId: roomData.roomId,
        roomName: roomData.roomName,
        roomType: roomData.roomType,
        totalAmount,
        startDate,
        endDate,
        maxGuests: roomData.maxGuests,
        metadata,  // Aggiungi metadata per passare tutte le informazioni utili
        created: new Date().toISOString(),
      };
  
      // Log dei dati per verifica
      console.log("Dati per la prenotazione:", payload);
  
      // Effettua la chiamata API al tuo endpoint per creare la sessione di pagamento
      const response = await axios.post(`${baseURL}/api/rooms/create-checkout-session`, { data: payload }).then((res) => {
        if(res.data.url) {
            window.location.href = res.data.url;
        }
    })
  
      // Esegui eventuali azioni dopo la risposta (es. redirect o conferma)
    } catch (error) {
      console.error("Errore nella prenotazione:", error);
    }
  };
  
  
  

  const handleNext = () => {
    if (photoIndex < images.length - 1) {
      setPhotoIndex(photoIndex + 1);
    }
  };

  const handlePrev = () => {
    if (photoIndex > 0) {
      setPhotoIndex(photoIndex - 1);
    }
  };

  return (
    <section className="my-8">
      <div className="bg-white rounded-xl shadow-md p-2 mb-2">
        <h2 className="text-4xl text-center font-semibold mb-4 text-headingColor">
          {roomName}
        </h2>
        <h4 className="text-3xl text-center font-normal text-headingColor">{roomType}</h4>
      </div>

      <div className="flex flex-col lg:flex-col gap-6 bg-lightOverlay shadow-md p-5 rounded-xl">
        <div className="space-y-4">
          {images && images.length > 0 ? (
            <>
              <img
                src={images[photoIndex]}
                alt="Main Room"
                className="w-full h-64 md:h-96 lg:h-650 object-cover rounded-lg cursor-pointer"
                onClick={() => setIsOpen(true)}
              />

              <div className="flex items-center w-full h-full">
                <button
                  onClick={handlePrev}
                  disabled={photoIndex === 0}
                  className={`p-2 shadow-lg bg-white rounded-full text-xl ${
                    photoIndex === 0 ? "cursor-not-allowed" : ""
                  }`}
                >
                  &#8592;
                </button>

                <div className="flex overflow-hidden justify-center w-full">
                  <div className="flex transition-transform duration-300">
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        className={`w-24 h-24 object-cover rounded-lg mx-1 cursor-pointer ${
                          photoIndex === index ? "border-2 border-blue-600" : ""
                        }`}
                        onClick={() => setPhotoIndex(index)}
                      />
                    ))}
                  </div>
                </div>

                <button
                  onClick={handleNext}
                  disabled={photoIndex >= images.length - 1}
                  className={`px-2 ${
                    photoIndex >= images.length - 1
                      ? "opacity-50 cursor-not-allowed"
                      : "p-2 shadow-lg bg-white rounded-full text-xl"
                  }`}
                >
                  &#8594;
                </button>
              </div>
            </>
          ) : (
            <p className="text-center text-gray-600">Nessuna immagine disponibile</p>
          )}
        </div>

        <div className="flex flex-col lg:flex-row gap-4">
          <div className="flex flex-col lg:w-1/2">
            <div className="bg-primary p-6 rounded-md shadow-sm">
              <h2 className="font-medium text-xl">Descrizione</h2>
              <p className="text-gray-600 text-sm">{description}</p>
              <div className="flex gap-2 text-center mt-4">
                <div className="p-2 bg-gray-200 rounded-md">
                  <span className="text-gray-600">Max Ospiti: {maxGuests}</span>
                </div>
              </div>
            </div>

            <div className="flex mt-4 mb-4">
              <span className="block text-headingColor text-2xl font-bold">Servizi</span>
            </div>

            <div className="flex flex-wrap gap-2">
              {amenities.map((amenity, index) => (
                <div key={index} className="flex-col p-2 shadow-sm rounded-md bg-primary">
                  <span className="text-sm text-gray-500">
                    <span className="font-semibold text-textColor text-sm">{amenity}</span>
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="p-6 rounded-lg shadow bg-primary lg:w-1/2 w-full">
            <h2 className="font-medium text-2xl">Prenotati</h2>
            <h3 className="text-xl font-bold text-textColor">
              €{pricePerNight}{" "}
              <span className="font-light text-gray-500">/per Notte</span>
            </h3>

            <p className="mt-4 text-sm mb-2">Seleziona le Date</p>

            <DateRange
              locale={it}
              onChange={(item) => setRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={range}
              editableDateInputs={true}
              className="shadow-md rounded-md custom-calendar sm:max-w-sm lg:w-full"
              style={{ width: "100%", margin: "0 auto" }}
            />

            <div className="mt-4 p-4 justify-center flex items-center rounded-sm shadow-md bg-gray-200">
              <p className="text-center">
                Pagherai{" "}
                <span className="block bg-white p-1 rounded-md font-semibold text-xl text-lime-600">
                  €{pricePerNight * numberOfNights}
                </span>{" "}
                per <br />
                <span className="block bg-white p-1 rounded-md font-semibold text-xl text-lime-600">
                  {numberOfNights}{" "}
                  {numberOfNights === 1 ? "Notte" : "Notti"}
                </span>
              </p>
            </div>

            <div
              onClick={handleBooking}
              className="w-full text-center mt-4 py-2 bg-headingColor text-white rounded-md shadow-md cursor-pointer"
            >
              Continua alla Prenotazione
            </div>
          </div>
        </div>
      </div>


      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </section>
    
  );
};

export default RoomSection;
