export const setBookings = (data) => {
    return {
        type: "SET_BOOKINGS",
        bookings: data,
    }
};

export const getBookings = () => {
    return {
        type: "GET_BOOKINGS",
    }
};