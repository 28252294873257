import React from 'react';

const CookiePolicy = () => {
  return (
    <div>

        <h2 className="text-center mt-10 text-4xl font-bold">
            Per Saperne di piu sui cookie visita <br />
            <a className='text-2xl mb-10 text-headingColor font-light' href="https://policies.google.com/technologies/cookies?hl=it">Cookie Policy</a>
        </h2>

    </div>
  )
}

export default CookiePolicy