import React from "react";
import {
  GallerySection,
  GalleryStruttura,
  Faq,
  RoomAvailable,
  HeroSection,
  ContactForm,
  ReviewForm,
  ReviewsSlider
} from "../components";
import { motion } from "framer-motion";
import "../assets/css/main.css";

const Home = () => {
  return (
    <motion.div className="max-w-7xl grid grid-cols-1 md:grid-cols-1 gap-4 md:pt-20">
      <div className="mb-16">
        <HeroSection />
      </div>
      <div className="mb-16">
        <RoomAvailable />
      </div>
      <div className="mb-16">
        <GalleryStruttura />
      </div>
      <div className="mb-16 flex text-center justify-center">
        <ReviewsSlider />
      </div>
      <div className="mb-16">
        <GallerySection />
      </div>
      <div className="mb-16">
        <Faq />
      </div>
      <div className="mb-16">
        <ContactForm />
      </div>
      
    </motion.div>
  );
};

export default Home;
