import React from "react";
import { useSelector } from "react-redux";
import RoomCard from "./RoomCard"; // Verifica se RoomCard è un export default

const RoomAvailable = () => {
  const rooms = useSelector((state) => state.rooms.rooms);

  return (
    <div>
      <h2 className="text-2xl font-bold text-headingColor">
        Stanze disponibili
      </h2>
      <p className="mb-6">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error nihil ipsa id qui necessitatibus esse voluptatem et tempore, dignissimos laudantium dolor eum ab! Ipsa earum harum eaque voluptate nam delectus.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {rooms.length > 0 ? (
          rooms.map((room) =>
            room ? (
              <div
                key={room.roomId}
                className="cursor-pointer shadow-md bg-transparent"
              >
                <RoomCard room={room} />
              </div>
            ) : null
          )
        ) : (
          <p>Nessuna stanza disponibile.</p>
        )}
      </div>
    </div>
  );
};

export default RoomAvailable;
