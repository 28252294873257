

export const isActiveStyles =
  " text-md p-2 rounded-md text-primary font-semibold text-headingColor px-4 py-2 duration-100 transition-all ease-in-out bg-textColor";
  // p-2 hover:rounded-md hover:bg-orange-500 text-xl hover:text-white hover:scale-105 text-textColor

export const isNotActiveStyles =
  " text-sm p-2 hover:rounded-md text-textColor hover:text-headingColor duration-100 px-4 py-2 transition-all ease-in-out";

  export const services = [
    { id: 1, title: "Wi-Fi", category: "wifi" },
    { id: 2, title: "Colazione inclusa", category: "colazione" },
    { id: 3, title: "TV", category: "tv" },
    { id: 4, title: "Aria condizionata", category: "aria_condizionata" },
    { id: 5, title: "Pet-friendly", category: "pet_friendly" },
    { id: 6, title: "Minibar", category: "minibar" },
    { id: 7, title: "Servizio in camera", category: "servizio_in_camera" },
    { id: 8, title: "Trasporto da/per l'aeroporto", category: "trasporto_aeroporto" },
    { id: 9, title: "Deposito bagagli", category: "deposito_bagagli" },
    { id: 10, title: "Lavanderia", category: "lavanderia" },
    { id: 11, title: "Servizi per disabili", category: "servizi_disabili" },
    { id: 12, title: "Camere non fumatori", category: "non_fumatori" },
];

  
export const roomTypes = [
  { id: 1, type: "Singola" },
  { id: 2, type: "Doppia" },
  { id: 3, type: "Tripla" },
  { id: 4, type: "Quadrupla" },
  { id: 5, type: "Suite" },
  { id: 6, type: "Appartamento" },
  { id: 7, type: "Villa" },
  { id: 8, type: "Chalet" },
  { id: 9, type: "Bungalow" },
  { id: 10, type: "Casa Vacanze" },
  { id: 11, type: "Monolocale" },
  { id: 12, type: "Penthouse" },
  { id: 13, type: "Loft" },
  { id: 14, type: "Alloggio" },
  { id: 15, type: "Altro" },
];