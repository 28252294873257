import React, { useState } from "react";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // Importa lo stile per il lightbox
import { Castello, sanTeodoro, teatroUmberto } from "../assets";

const GallerySection = () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    { url: Castello, alt: "Casa Bianca", title: "Casa Bianca" },
    { url: sanTeodoro, alt: "Soggiorno Chic", title: "Soggiorno Chic" },
    { url: teatroUmberto, alt: "Camera Familiare", title: "Camera Familiare" },
  ];

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  return (
    <div className="w-full flex flex-col items-start mb-15">
      <div className="flex flex-col items-start justify-start gap-1">
        <p className="text-2xl text-headingColor font-bold">
          Foto Gallery centro storico
        </p>
        <p className="mb-6">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Itaque
          similique nemo laudantium excepturi asperiores dicta dolorum
          dignissimos qui laboriosam quisquam?
        </p>
      </div>
      <div className="relative w-full text-center">
        <div className="flex">
          {/* Immagine principale */}
          <div className="w-2/3 pr-4">
            <img
              src={images[0].url}
              alt={images[0].alt}
              className="w-full h-96 object-cover rounded-lg cursor-pointer shadow-md"
              onClick={() => openLightbox(0)}
            />
          </div>

          {/* Immagini più piccole */}
          <div className="flex flex-col w-1/3 space-y-2">
            {images.slice(1).map((image, i) => (
              <img
                key={i}
                src={image.url}
                alt={image.alt}
                className="w-full h-48 object-cover rounded-lg cursor-pointer shadow-md"
                onClick={() => openLightbox(i + 1)} // Aggiorna l'indice per le altre immagini
              />
            ))}
          </div>
        </div>
        <p className="text-gray-700 my-4">Descrizione centro storico</p>

        <Link
          to={"/gallery"}
          className="bg-headingColor text-white px-6 py-2 rounded-md hover:bg-headingColor transition-colors"
        >
          Vai Alla Gallery
        </Link>
      </div>

      {/* Lightbox per visualizzare le immagini a schermo intero */}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[currentIndex].url}
          nextSrc={images[(currentIndex + 1) % images.length].url}
          prevSrc={
            images[(currentIndex + images.length - 1) % images.length].url
          }
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setCurrentIndex((currentIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setCurrentIndex((currentIndex + 1) % images.length)
          }
          imageCaption={images[currentIndex].title} // Mostra il titolo dell'immagine
        />
      )}
    </div>
  );
};

export default GallerySection;
